import React from 'react'
import Logo from '../assets/new4.png';
import useScrollDirection  from './useScrollDirection'
import {Link} from 'react-router-dom';

const Header = () => {
  const scrollDirection = useScrollDirection();

  return (
    <div className={`flex lg:pr-20 lg:pl-24 pl-6 pr-8 lg:py-2 py-4 bg-[#fffdf3] border-none justify-between w-screen rounded-b-md z-50 sticky top-0 transition-all duration-500 ${scrollDirection === "down" ? "-top-32" : "top-0"}`}>
            <Link to='/'>
              <div className='flex lg:gap-4 gap-3 justify-center items-center'>
                <img src={Logo} className='lg:w-60 lg:h-24 w-24 h-10 opacity-90' alt=''/>
                {/* <div className='flex flex-col justify-center items-center gap-0'>
                  <h1 className='lg:text-3xl tracking-widestt text-2xl font-semibold font-roboto'>EDEN</h1>
                  <p className='text-xs font-extralight'>VENTURE GROUP</p>
                </div> */}
              </div>
            </Link>
            <div className='flex justify-center items-center lg:gap-6 gap-3'>
            <span className='font-semibold uppercase lg:text-base text-xs hover:text-[#03989d] cursor-pointer'>what's new</span>
            <Link to='/profile'><span className='font-semibold uppercase lg:text-base text-xs hover:text-[#03989d] cursor-pointer'>About us</span></Link>
            </div>
    </div>
  )
}

export default Header;
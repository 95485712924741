import React from 'react';
import Brands from './Brands';
import Contact from "./Contact";
import Header from "./Header";
import Hero from "./Hero";
// import Model from "./Model";
import Universe from "./Universe";
import What from "./What";
import Who from "./Who";

const Home = () => {
  return (
    <div className='opacity-100 bg-[#fffdf3]'>
      <Header />
      <Hero />
      <What />
      <Who />
      {/* <Model /> */}
      <Brands />
      <Universe />
      <Contact />
    </div>
  )
}

export default Home;
import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
// import Heroo from '../assets/hero.png'
// import imageSlide from './data2';
// import { useState, useEffect } from 'react';



const Hero = () => {

  // const[currentSlide, setCurrentSlide] = useState(0);
  
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if(currentSlide===2){
  //       setCurrentSlide(0)
  //     }else{
  //       setCurrentSlide(currentSlide+1)
  //     }
  //   },5000)
  //   return () => clearTimeout(timer)
  // }, [currentSlide])

  // const bgImageStyle = {
  //   backgroundImage: `url(${imageSlide[currentSlide].url})`,
  //   backgroundPosition: 'center',
  //   backgroundSize: 'cover',
  //   height: '50rem',
  // }

  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '20%'])

  return (
    <motion.div className="flex text-white lg:h-[50rem] h-[43rem] lg:bg-[url('../assets/hero.png')] bg-[url('../assets/hero2.png')] bg-cover" style={{y}}>
      {/* <motion.div className='lg:w-screen absolute -z-20' style={{y}}>
        <img src={Heroo} alt='' className='lg:h-[50rem] h-[40rem] lg:w-full'/>
      </motion.div> */}
      <div className='flex flex-col lg:gap-6 gap-3 lg:pl-40 lg:mt-48 pl-6 pr-8 lg:pr-0 mt-16 relative'>   
        <span className='text-lg font-extrabold uppercase lg:tracking-widestt tracking-wider'>Investing in eden sucess</span>
        <span className='lg:text-4xl text-xl lg:font-normal font-light lg:tracking-widest lg:w-[35rem] lg:leading-[3rem] leading-9'>We envision an Africa where women,young people and creatives can access the right network and support towards a transformational and rewarding career.</span>
        <div className='lg:w-[70rem]'>
         <span className='font-thin text-xs lg:text-sm absolute bottom-3 lg:right-0 right-6'>*Photo by <a href="https://unsplash.com/@divineeffiong?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Divine Effiong</a> on <a href="https://unsplash.com/photos/5ZrcEPi8ihE?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </span>
        </div>
      </div>
    </motion.div>
  )
}

export default Hero;





import React from 'react'
import Contact from './Contact';
import Data from './Dataa';
import Founder from './Founder';
import Header from './Header';

const Profile = () => {
  return (
    <div className='flex flex-col opacity-100'>
        <Header />
        <Founder />
        <Data />
        <Contact />
    </div>
  )
}

export default Profile;
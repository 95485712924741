export const whoSlide = [
    {
        url: 'Jude.png',
    },
    {
        url: 'Amaka.png',
    },
    {
        url: 'Eniola.png',
    },
]

export const whoSlide1 = [
    {
        url: 'Titi.png',
    },
    {
        url: 'Sophia.png',
    },
    {
        url: 'Ose.png',
    },
]

export const whoSlide2 = [
    {
        url: 'Kamal.png',
    },
    {
        url: 'Nkiru.png',
    },
    {
        url: 'Funmbi.png',
    },
]








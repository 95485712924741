import React from 'react';
import Logo from '../assets/logo.png';
import Logo2 from '../assets/logo2.png';
import Logo3 from '../assets/logo3.png';
import Logo4 from '../assets/logo4.png';
import Logo5 from '../assets/logo5.png';
import Logo6 from '../assets/logo6.png';
// import Logo7 from '../assets/logo7.png';
import Logo8 from '../assets/logo8.png';
import Logo9 from '../assets/logo9.png';
import Logo10 from '../assets/logo10.png';
import Logo11 from '../assets/logo11.png';
import Logo12 from '../assets/logo12.png';
import Logo13 from '../assets/logo13.png';
import Logo14 from '../assets/logo14.png';
import Logo15 from '../assets/logo15.png';
import Logo16 from '../assets/logo16.png';
import Logo17 from '../assets/logo17.png';
import Logo18 from '../assets/logo18.png';
import Logo19 from '../assets/logo19.png';
import Logo20 from '../assets/logo20.png';
import Logo21 from '../assets/logo21.png';



const Universe = () => {
  return (
    <div className='flex bg-[#fffdf3] lg:px-32 px-6 mt-12 flex-col lg:gap-8 gap-0'>
      <div className='lg:flex hidden justify-center items-center lg:gap-32 gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo2} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo3} alt='' className='lg:w-32 w-12 lg:h-32 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo5} alt='' className='lg:w-[9rem] w-12 lg:h-36 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo10} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo} alt='' className='lg:w-20 w-12 lg:h-12 h-12 flex items-center'/></div>
      </div>
      <div className='lg:flex hidden justify-center items-center lg:gap-32 gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo8} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo21} alt='' className='lg:w-32 w-12 lg:h-32 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo11} alt='' className='lg:w-24 w-12 lg:h-24 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo18} alt='' className='lg:w-24 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo13} alt='' className='lg:w-24 w-12 lg:h-8 h-12 flex items-center'/></div>
      </div>
      <div className='lg:flex hidden justify-center items-center lg:gap-32 gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo20} alt='' className='lg:w-36 w-14 lg:h-36 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo4} alt='' className='lg:w-20 w-14 lg:h-20 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo17} alt='' className='lg:w-24 w-14 lg:h-24 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo12} alt='' className='lg:w-36 w-14 lg:h-6 h-10 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo14} alt='' className='lg:w-32 w-14 lg:h-28 h-14 flex items-center'/></div>
      </div>
      <div className='lg:flex hidden justify-center items-center lg:gap-32 gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo9} alt='' className='lg:w-20 w-14 lg:h-28 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo15} alt='' className='lg:w-20 w-14 lg:h-12 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo6} alt='' className='lg:w-20 w-14 lg:h-20 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo19} alt='' className='lg:w-20 w-14 lg:h-28 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo16} alt='' className='lg:w-36 w-14 lg:h-12 h-14 flex items-center'/></div>
      </div>


      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo2} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo3} alt='' className='lg:w-32 w-16 lg:h-32 h-16 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo5} alt='' className='lg:w-[9rem] w-20 lg:h-36 h-20 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo10} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo} alt='' className='lg:w-20 w-12 lg:h-12 h-6 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo8} alt='' className='lg:w-20 w-12 lg:h-20 h-12 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo21} alt='' className='lg:w-32 w-12 lg:h-32 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo11} alt='' className='lg:w-24 w-12 lg:h-24 h-12 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo18} alt='' className='lg:w-24 w-12 lg:h-20 h-6 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo13} alt='' className='lg:w-24 w-12 lg:h-8 h-6 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo20} alt='' className='lg:w-36 w-20 lg:h-36 h-16 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo4} alt='' className='lg:w-20 w-12 lg:h-20 h-14 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo17} alt='' className='lg:w-24 w-14 lg:h-24 h-14 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo12} alt='' className='lg:w-36 w-14 lg:h-6 h-3 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo14} alt='' className='lg:w-32 w-20 lg:h-28 h-16 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex justify-center items-center gap-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo16} alt='' className='lg:w-36 w-14 lg:h-12 h-10 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo15} alt='' className='lg:w-20 w-14 lg:h-12 h-8 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo6} alt='' className='lg:w-20 w-14 lg:h-20 h-14 flex items-center'/></div>
      </div>
      <div className='lg:hidden flex items-center gap-4 pl-3 mt-4'>
        <div className='lg:w-40 lg:h-40 w-24 h-24 flex justify-center items-center'><img src={Logo19} alt='' className='lg:w-20 w-14 lg:h-28 h-20 flex items-center'/></div>
        <div className='lg:w-40 lg:h-40 w-24 h-20 flex justify-center items-center'><img src={Logo9} alt='' className='lg:w-20 w-14 lg:h-28 h-20 flex items-center'/></div>
      </div>   

    </div>
  )
}

export default Universe;




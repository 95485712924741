import React from 'react';
import { whoSlide, whoSlide1, whoSlide2 } from './data1';
import { useState, useEffect } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { motion, useScroll, useTransform } from 'framer-motion';
// import ImageSlider, { Slide } from "react-auto-image-slider";


const Who = () => {
  const[currentSlide, setCurrentSlide] = useState(0);
  const[currentSlide1, setCurrentSlide1] = useState(0);
  const[currentSlide2, setCurrentSlide2] = useState(0);

  const[toggleMore, setToggleMore] = useState();
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '70%'])
  

  useEffect(() => {
    const timer = setTimeout(() => {
      if(currentSlide===2){
        setCurrentSlide(0)
      }else{
        setCurrentSlide(currentSlide+1)
      }
    },2000)
    return () => clearTimeout(timer)
  }, [currentSlide]);

  useEffect(() => {
    const timerr = setTimeout(() => {
      if(currentSlide1===2){
        setCurrentSlide1(0)
      }else{
        setCurrentSlide1(currentSlide1+1)
      }
    },3000)
    return () => clearTimeout(timerr)
  }, [currentSlide1]);

  useEffect(() => {
    const timerrr = setTimeout(() => {
      if(currentSlide2===2){
        setCurrentSlide2(0)
      }else{
        setCurrentSlide2(currentSlide2+1)
      }
    },4000)
    return () => clearTimeout(timerrr)
  }, [currentSlide2]);

  return (
    <div className='flex lg:h-[40rem] h-[20rem] relative bg-[#b5e4d8] flex-col'>
      <motion.div className='flex lg:gap-8 gap-2 absolute top-28 lg:left-[15rem] left-[4rem]'  style={{y}}>
        <div className='lg:w-64 lg:h-[17rem] w-20 h-28'><img src={whoSlide[currentSlide].url} alt='' className='lg:w-64 lg:h-72 w-20 h-24'/></div>
        <div className='lg:w-64 lg:h-[17rem] w-20 h-28'><img src={whoSlide1[currentSlide1].url} alt='' className='lg:w-64 lg:h-72 w-20 h-24'/></div>
        <div className='lg:w-64 lg:h-[17rem] w-20 h-28'><img src={whoSlide2[currentSlide2].url} alt='' className='lg:w-64 lg:h-72 w-20 h-24'/></div>
      </motion.div>
      <div className='flex flex-col absolute lg:top-20 lg:left-20 lg:gap-4 lg:w-[22rem] lg:p-8 bg-[#03989dd6] left-8 top-8 gap-2 w-[16rem] p-4'>
        <span><p className='lg:text-2xl text-lg font-medium lg:font-bold text-black/90 opacity-80'>Who we are</p></span>
        <span><p className='lg:text-base text-sm font-extralight lg:font-light text-white/80'>EVG is a collective of social entrepreneurs working together... <span className='font-semibold text-black/70 cursor-pointer' onClick = {() => {setToggleMore(true)}}>see more</span></p></span>
      </div>

       {
        toggleMore && (
          <div className='flex flex-col absolute lg:top-20 lg:left-20 lg:gap-4 lg:w-[22rem] lg:p-8 bg-[#03989dd6] left-8 top-8 gap-2 w-[16rem] p-4'>
            <span><p className='lg:text-2xl text-lg font-medium lg:font-bold text-black/90 opacity-80'>Who we are</p></span>
           <span><p className='g:text-base text-sm font-extralight lg:font-light  text-white/80'>Eden Venture Group (EVG) is a collective of social entrepreneurs working together to develop, support and empower underserved and underrepresented demographics in emerging economies. We believe everyone deserves an opportunity to create meaningful impact in the world and we are providing  people, organizations and institutions the skills, support and the right network to do so.</p></span>
           <span className='text-black/60 flex justify-center' onClick = {() => {setToggleMore(false)}}><MdOutlineCancel className='w-6 h-6'/></span>
          </div>
        )
      }
    </div>
  )
}

export default Who;


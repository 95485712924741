import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';


const Founder = () => {

  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '20%'])

  return (
    <motion.div className='flex bg-[#b5e4d8] lg:justify-center lg:items-center px-12 py-12 lg:px-32 lg:py-0  relative -z-50 h-[25rem] lg:h-[30rem]' style={{y}}>
        <div className='flex flex-col lg:gap-8 gap-4 lg:px-40 py-5 relative'>
            <p className='lg:text-lg font-medium text-sm'>
            Eden Venture Group (EVG) is a collective of social entrepreneurs working together to develop, support and empower underserved and underrepresented demographics in emerging economies. We believe everyone deserves an opportunity to create meaningful impact in the world and we are providing  people, organizations and institutions the skills, support and the right network to do so.We are a social enterprise curating, developing and positioning social entrepreneurs for scale. Meet our team of social entrepreneurs and disruptors, the people we do great work with.
            </p>
        </div>
    </motion.div>
  )
}

export default Founder;
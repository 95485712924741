import React from 'react';
import TextAnimation from "react-text-animations";
// import Img from './Img';
// import { FcCollaboration } from 'react-icons/fc'
// import { motion, useAnimation } from 'framer-motion';
// import { useInView } from "framer-motion"


const What = () => {
    // const ref = useRef(null)
    // const inView = useInView(ref, { once: true })
    // const animation = useAnimation();
    // const animations = useAnimation();
    // const animationss = useAnimation();

    // useEffect(() => {
    //     if(inView){
    //         animation.start({
    //             x: 0,
    //             transition: {
    //                 type: 'spring', duration: 3, bounce: 0.2
    //               }
    //         })
    //     }
    //     if(!inView){
    //         animation.start({x: '-100vw'})
    //     };
    //     if(inView){
    //         animations.start({
    //             x: 0,
    //             transition: {
    //                 type: 'spring', duration: 2, bounce: 0.2
    //               }
    //         })
    //     }
    //     if(!inView){
    //         animations.start({x: '100vw'})
    //     };
    //     if(inView){
    //         animationss.start({
    //             opacity: 1,
    //             transition: {
    //                 ease: 'easeOut',
    //                 duration: 2, delay: 0.3
    //               }
    //         })
    //     }
    //     if(!inView){
    //         animationss.start({opacity: 0})
    //     }
    // })


  return (
    <div className='flex lg:mt-20 mt-12 flex-col lg:flex-row'>
        <div className='flex basis-[60%] h-48'>
            <div className='flex flex-col w-[60%] bg-[#3d3a4b] justify-center items-center text-white h-32 lg:h-auto'>
                <h1 className='lg:text-7xl text-2xl font-semibold font-roboto'>EDEN</h1>
                <p className='lg:text-2xl text-base lg:font-normal font-medium'>VENTURE GROUP</p>
            </div>
            <div className='flex items-center bg-[#fffdf3] w-[40%]'></div>
        </div>
        <div className='flex basis-[40%] justify-center items-center bg-[#d16666] text-white lg:h-auto p-12'>
            <h1 className='uppercase lg:text-2xl text-lg font-medium'>
            <TextAnimation.Slide 
              target="TELLNG COMPELLNG STORES" 
              text={['TELLING COMPELLING STORIES','SOCIAL TRANSFORMATION','POWERFUL  NETWORK', ]} 
              cname="gap-4"
              id="textAnimation__slide"
              animation={{
              duration:2000,
              delay:4000,
              timingFunction:'ease-out',
                }}
              loop={true}>
              TELLNG COMPELLNG STORES
          </TextAnimation.Slide>
            </h1>
        </div>
    </div>
  )
}

export default What;